<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import qs from 'qs'
import type { DealType, Product } from '~/types'

const route = useRoute('shop-slug')

const config = useRuntimeConfig()

const form = ref({
  deal_type_slug: route.params.slug,
  deal_type: 'GENERIC',
})

const { data, refresh } = await useFetch<DealType>(`/api/deal-types/${route.params.slug}`, {
  baseURL: config.public.amalo.baseURL.api,
  query: form.value,
  watch: false,
})

watchDebounced(form, () => {
  refresh()
}, { debounce: 500, maxWait: 1000 })

if (!data.value) {
  throw createError({
    statusCode: 404,
    statusMessage: 'Vi kunne ikke finde produktet',
    fatal: true,
  })
}

useServerSeoMeta({
  title: data.value?.deal_type_presentation.seo_title,
  description: data.value?.deal_type_presentation.seo_description,
  twitterTitle: data.value?.deal_type_presentation.seo_og_title,
  twitterDescription: data.value?.deal_type_presentation.seo_og_description,
  ogTitle: data.value?.deal_type_presentation.seo_og_title,
  ogImage: data.value?.deal_type_presentation.seo_og_image,
  ogDescription: data.value?.deal_type_presentation.seo_og_description,
})

const deal = computed(() => data.value as DealType)
const presentation = computed(() => deal.value.deal_type_presentation)

const lines = computed(() => deal.value.lines)

const price = computed(() => {
  if (!lines.value)
    return 0
  return lines.value.reduce((acc, line) => acc + (line.product.price * 1.25 * line.quantity) * (1 - (line.discount / 100)), 0) * 0.01
})

const fullPrice = computed(() => {
  if (!lines.value)
    return 0
  return lines.value.reduce((acc, line) => acc + (line.product.price * line.quantity), 0) * 1.25 * 0.01
})

const product = computed<Product>(() => {
  return {
    name: presentation.value.title,
    tagLine: presentation.value.tag_line,
    images: presentation.value.images?.map((image, index) => ({
      id: index,
      name: image.alt,
      alt: image.alt,
      src: image.url,
    })),
    price: price.value,
    fullPrice: fullPrice.value,
    description: presentation.value.description,
    details: (presentation.value.facts ?? []).map(fact => ({
      name: fact.title,
      content: fact.content,
    })),
  }
})

function submitHandler(_values: any) {
  pushEventToDataLayer('add_to_cart', {
    currency: 'DKK',
    value: price.value,
    items: data.value?.lines.map(line => ({
      item_id: line.product.id,
      item_name: line.product.name,
      discount: line.discount,
      price: (line.product.price * 1.25) * 0.01,
      quantity: line.quantity,
    })),
  })

  const { deal_type_slug, deal_type, ...rest } = form.value
  const query = { inputs: { ...rest }, deal_type_slug, deal_type }

  return navigateTo(`/shop/checkout?${qs.stringify(query)}`)
}

function pushEventToDataLayer(eventName: string, properties: Record<string, any>) {
  const dataLayer = window.dataLayer = window.dataLayer || []
  dataLayer.push({
    event: eventName,
    ...properties,
  })
}

onMounted(() => {
  pushEventToDataLayer('view_item', {
    currency: 'DKK',
    value: price.value,
    items: data.value?.lines.map(line => ({
      item_id: line.product.id,
      item_name: line.product.name,
      discount: line.discount,
      price: (line.product.price * 1.25) * 0.01,
      quantity: line.quantity,
    })),
  })
})

function defaultPlaceholder(presentational_type: DealType['inputs'][number]['presentational_type']) {
  switch (presentational_type) {
    case 'email':
    case 'number':
    case 'text':
    case 'tel':
    case 'textarea':
      return 'Indtast venligst'
    case 'checkbox':
    case 'radio':
    case 'dropdown':
    case 'toggle':
    case 'slider':
      return 'Vælg venligst'
    default:
      return ''
  }
}

function formKitProps(input: DealType['inputs'][number]) {
  const values = {
    id: input.name,
    key: input.name,
    name: input.slug,
    placeholder: defaultPlaceholder(input.presentational_type),
    type: input.hidden ? 'hidden' : input.presentational_type,
    min: input.min ?? undefined,
    max: input.max ?? undefined,
    help: input.help_text ?? undefined,
    label: input.required ? `${input.name} *` : input.name,
    multiple: input.multiple ?? false,
    step: input.step ?? 1,
    accept: input.accept,
    value: input.default_value,
    calculated_value: input.calculated_value,
    validation: input.required ? 'required' : undefined,
  }

  if (input.options && input.options.length > 0)
    values.options = input.options

  return values
}
</script>

<template>
  <div>
    
<FormKitLazyProvider config-file="true">
<div class="bg-white">
      <PageContainer>
        <ProductPresentation :product="product">
          <FormKit
            v-model="form"
            type="form"
            :actions="false"
            @submit="submitHandler"
          >
            <div class="space-y-6 mt-6">
              <template v-for="input in data?.inputs" :key="input.id">
                <FormKit
                  v-if="input.presentational_type !== 'file'"
                  v-bind="formKitProps(input)"
                />
              </template>
            </div>

            <button
              type="submit"
              class="rounded-md mt-6 bg-blue-500 px-3.5 py-3.5 text-base w-full font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Gå til bestilling
            </button>
          </FormKit>
        </ProductPresentation>
      </PageContainer>
    </div>

    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-blue-900 prose prose-h2:text-blue-950 prose-h2:font-bold prose-h2:text-2xl prose-h2:lg:text-4xl prose-p:text-base prose-p:text-blue-950/80 prose-li:text-blue-950/80 space-y-12">
      <div v-html="presentation.description_secondary" />
    </div>
</FormKitLazyProvider>

  </div>
</template>
